






































































// todo: отрефакторить стили

import { Component, Prop, Vue } from 'vue-property-decorator'
import { Route } from 'vue-router'
import { BButton } from 'bootstrap-vue'
@Component({
  components: { BButton }
})
export default class ProductCard extends Vue {
  @Prop({ default: 'small', type: String })
  readonly size!: 'small'|'big'

  @Prop({ type: [Object, String], default: '#' })
  readonly productRoute!: Route | string | null

  @Prop({ type: String, default: null })
  readonly preview!: string | null

  @Prop({ type: String, default: null })
  readonly tag!: 'new'| 'discount' | null

  @Prop({ type: String, default: 'Название' })
  readonly title!: string

  @Prop({ type: Number, default: 0 })
  readonly weight!: number

  @Prop({ type: Number, default: 0 })
  readonly kcal!: number

  @Prop({ type: Number, default: 0 })
  readonly price!: number

  @Prop({ type: Number, default: 0 })
  readonly netPrice!: number

  async onLink() {
    // TODO: Найти другое решение. При выборе фильтра, при выборе продукта, осуществляется дополнительный переход на каталог
    const fullPath = this.$router.currentRoute.fullPath

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await this.$router.push(this.productRoute)
    if (fullPath.includes('subcategory=')) {
      this.$router.back()
    }
  }
}
