var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'col-4 col-xl-3': _vm.size === 'big',
    'col-3 col-xl-2': _vm.size === 'small'
  }},[_c('div',{staticClass:"mb-3 mt-2 row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"shadow",style:(("background-color: #f6f6f6;\n         height: auto;\n         border-radius: 24px;\n         padding:" + (_vm.size === 'big' ? 17 : 12) + "px;"))},[_c('a',{attrs:{"href":"#0"},on:{"click":function($event){$event.preventDefault();return _vm.onLink.apply(null, arguments)}}},[_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(_vm.preview),expression:"preview",arg:"background-image"}],staticClass:"product-card__image",class:{
              'wbs-image-wrapper-right': _vm.size === 'big',
              'wbs-image-wrapper-right-six': _vm.size === 'small'
            }})])]),(_vm.tag)?_c('div',{staticClass:"position-absolute",staticStyle:{"top":"0","left":"0"}},[(_vm.tag === 'discount')?_c('div',{staticClass:"discount_pic"},[_vm._v(" % ")]):_vm._e(),(_vm.tag === 'new')?_c('div',{staticClass:"new_item_pic"},[_vm._v(" new ")]):_vm._e()]):_vm._e()])]),_c('h6',{staticClass:"product-card__title",class:{
      'wbs-text-overflow-main-4': _vm.size === 'big',
      'wbs-text-overflow-main-6': _vm.size === 'small'
    }},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('p',[_vm._t("default")],2),_c('p',{staticClass:"text-muted wbs-text-overflow-main-kkal"},[_vm._v(" "+_vm._s(_vm.kcal)+" Ккал, "+_vm._s(_vm.weight)+" г. ")])])}
var staticRenderFns = []

export { render, staticRenderFns }