// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../src/assets/images/svg/button-idontknowwhatiwant.svg");
exports = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".product-card__title[data-v-77f02228]{font-family:Museo Cyrl\\ 500,sans-serif;font-weight:500}.product-card__image[data-v-77f02228]{background-size:contain;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}", "",{"version":3,"sources":["/builds/Lvj_REu9/0/workeat/workeat-terminal-pwa/src/components/ProductCard.vue"],"names":[],"mappings":"AA2HE,sCACE,sCAAA,CACA,eAAA,CAGF,sCACE,uBAAA,CACA,wDAAA","file":"ProductCard.vue","sourcesContent":["\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n.product-card {\n  &__title {\n    font-family: 'Museo Cyrl 500', sans-serif;\n    font-weight: 500;\n  }\n\n  &__image {\n    background-size: contain;\n    background-image: url(\"../../src/assets/images/svg/button-idontknowwhatiwant.svg\");\n  }\n}\n"]}]);
// Exports
module.exports = exports;
